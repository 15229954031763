<!-- 平均功率因数 -->
<template>
  <div id="energy-report">
    <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="true"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>平均功率因数</span>
      </div>
      <div class="content">
        <ul class="tabs">
          <li
            v-for="(item, index) in tabsData"
            :key="index"
            :class="select === index ? 'select' : ''"
            @click="switchTabs(index)"
          >
            {{ item }}
          </li>
        </ul>
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期:">
              <el-date-picker
                type="date"
                v-model="formData.date1"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-arrow-left"
                >上一日</el-button
              >
              <el-button type="primary"
                >下一日<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
              <el-button type="primary" icon="el-icon-search">查询</el-button>
              <el-button
                type="primary"
                icon="el-icon-download"
                @click="exportDialog = true"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div id="chart"></div>
        <el-table class="table" :data="tableData">
          <el-table-column align="center" prop="name" label="回路名称">
          </el-table-column>
          <el-table-column align="center" prop="zero" label="日期">
          </el-table-column>
          <el-table-column align="center" prop="one" label="正向有功点度">
          </el-table-column>
          <el-table-column align="center" prop="two" label="反向有功点度">
          </el-table-column>
          <el-table-column align="center" prop="three" label="正向有功点度">
          </el-table-column>
          <el-table-column align="center" prop="four" label="反向有功点度">
          </el-table-column>
          <el-table-column align="center" prop="five" label="平均功率因数">
          </el-table-column>
      
        </el-table>
      </div>
    </div>
    <el-dialog title="导出" :visible.sync="exportDialog" width="436px">
      <div class="dialog-content">确定要导出数据吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialog = false">取 消</el-button>
        <el-button type="primary" @click="exportDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { nextTick } from "vue";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      exportDialog: false,
      tabsData: ["日报", "月报",],
      tableData: [
        {
          name: "主进线柜",
          zero: "2023-11-01",
          one: "1210",
          two: "0",
          three: "42",
          four: "12",
          five: "0.99",
        },
        {
          name: "主进线柜",
          zero: "2023-11-01",
          one: "1210",
          two: "0",
          three: "42",
          four: "12",
          five: "0.99",
        },
        {
          name: "主进线柜",
          zero: "2023-11-01",
          one: "1210",
          two: "0",
          three: "42",
          four: "12",
          five: "0.99",
        },
      ],
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      checkedNodes: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "label",
      },
      treeData: [
        {
          id: 1,
          label: "低压1",
          children: [
            {
              id: 11,
              label: "进线柜",
            },
            {
              id: 12,
              label: "电容",
            },
            {
              id: 13,
              label: "有源滤波",
            },
            {
              id: 14,
              label: "联络柜",
            },
            {
              id: 14,
              label: "馈线1",
            },
            {
              id: 14,
              label: "馈线2",
            },
          ],
        },
        {
          id: 2,
          label: "低压2",
          children: [
            {
              id: 15,
              label: "进线柜",
            },
            {
              id: 16,
              label: "电容",
            },
            {
              id: 17,
              label: "有源滤波",
            },
            {
              id: 18,
              label: "联络柜",
            },
            {
              id: 19,
              label: "馈线1",
            },            {
              id: 20,
              label: "馈线2",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    this.drawChart();
  },

  methods: {
    switchTabs(index) {
      this.select = index;
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      console.log(this.checkedNodes);
    },
    drawChart() {
      var chart = echarts.init(document.getElementById("chart"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        legend: {
          show: false,
          right:'1%',
        },
        color: ['#14bf9b','#e0ab34',"#176ad2",'#176ad2'],
        xAxis: {
          data: [
            "2023-11-01",
            "2023-11-02",
            "2023-11-03",
            "2023-11-04",
            "2023-11-05",
            "2023-11-06",
            "2023-11-07",
          ],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 12,
            },
          },
        },
        yAxis: {
          name: "有功电度/无功电度",
          nameTextStyle: {
            color: "#aed6ff",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "1",
            type: "bar",
            barWidth: 20,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "2",
            type: "bar",
            barWidth: 20,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "3",
            type: "bar",
            barWidth: 20,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "4",
            type: "bar",
            barWidth: 20,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
  },
};
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#energy-report {
  width: 100%;
  min-width: 1621px;
  min-height: 903px;
  background: url("../../assets/images/routerImg.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}
/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}
.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}
::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}
::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}
::v-deep.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}
.main {
  width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* tabs */
.tabs {
  width: 1336px;
  background-color: rgb(4, 25, 66);
  display: flex;
}
.tabs li {
  width: 85px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
}
.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}
/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
}
.query-form :deep() .el-form {
  height: 40px;
}
.form :deep() .el-icon-date {
  color: #aed6ff;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}
/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}
/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}
/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog__footer{
 padding-top: 0;
}
.dialog-content {
  font-size: 16px;
  color: #c9d4f1;
  font-weight: 700;
  margin: 42px 0 64px 32px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}
.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

#chart {
  width: 1280px;
  height: 316px;
}
</style>
